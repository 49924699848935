import { useClassName } from '@/styles/JwVideoTeaser';
import { Component, ComponentProps } from '@/types/component';
import useLazyCallback, { RefType } from 'lib/hooks/useLazyCallback';
import mergeProps from 'lib/utils/mergeProps';

import { useCallback } from 'react';

export interface VideoTeaserMediaVideoProps extends ComponentProps<'video'> {
  thumbnail: string;
  mediaId: string;
  disableLazyLoad?: boolean;
}

export const VideoTeaserMediaVideoComponent: Component<VideoTeaserMediaVideoProps> = ({
  children,
  thumbnail,
  disableLazyLoad,
  mediaId,
  ...props
}) => {
  const playVideoTeaser = useCallback(
    (elementRef: RefType<HTMLVideoElement>) => {
      elementRef.current.poster = thumbnail;
      elementRef.current.src = elementRef.current.dataset.src as string;
      elementRef.current?.load();
    },
    [thumbnail]
  );

  const videoRef = useLazyCallback<HTMLVideoElement>(playVideoTeaser);

  const className = useClassName('media_video', props);

  return (
    <video
      ref={videoRef}
      data-src={`https://cdn.jwplayer.com/v2/media/${mediaId}/poster.mp4?width=640`}
      {...mergeProps(
        {
          className,
          muted: true,
          playsInline: true,
          autoPlay: true,
          loop: true,
          poster: disableLazyLoad ? thumbnail : '',
        },
        props
      )}
    />
  );
};
