import { useClassName } from '@/styles/JwVideoTeaser';

import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export interface VideoTeaserMediaProps extends ComponentProps<'figure'> {}

export const VideoTeaserMediaComponent: Component<VideoTeaserMediaProps> = ({ children, ...props }) => {
  const className = useClassName('media', props);
  return <figure {...mergeProps({ className }, props)}>{children}</figure>;
};
