import { useClassName } from '@/styles/JwVideoTeaser';
import { Component, ComponentProps } from '@/types/component';
import PlayIcon from './icons/play-icon.svg';

import mergeProps from 'lib/utils/mergeProps';

export interface VideoTeaserIconProps extends ComponentProps<'svg'> {}

export const VideoTeaserIconComponent: Component<VideoTeaserIconProps> = (props) => {
  const className = useClassName('media_icon', props, { defaults: { colors: 'primary' } });

  return <PlayIcon {...mergeProps({ className }, props)} />;
};
