import { useClassName } from '@/styles/JwVideoTeaser';
import { ComponentProps } from '@/types/component';

import { Component } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export interface VideoTeaserContentTextProps extends ComponentProps<'div'> {}

export const VideoTeaserContentTextComponent: Component<VideoTeaserContentTextProps> = ({ children, ...props }) => {
  const className = useClassName('content_text', props);

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};
