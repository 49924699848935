import { ContentProps } from '@/types/content';
import logger from 'lib/utils/logger';

export const withDebugMetadata = (props?: Partial<ContentProps>) => {
  if (process.env.COMPONENTS_METADATA_DEBUG !== 'true') {
    return undefined;
  }

  try {
    const metadata = JSON.stringify(props ?? {}).replace(/"/g, '');
    return { 'data-debug': metadata };
  } catch (error) {
    logger.catch({ props, error });
    return undefined;
  }
};
