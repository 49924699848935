/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneTag as ResolvedStandaloneTag } from 'base/components/standalone/Tag';

export const Tag = ResolvedStandaloneTag;

export type TagProps = PropsFromComponent<typeof Tag>;

export const TagWith = (extras: DynamicStandaloneExtras): typeof Tag => {
    return function Tag(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Tag');
        return <ResolvedStandaloneTag {...mergeProps({ options: { theme } }, props)} />;
    }
}