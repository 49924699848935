import { Link, LinkProps } from '@/components/Link';
import { StandaloneComponent } from '@/types/component';
import { mergeOptions } from 'lib/utils/mergeProps';

export interface VideoTeaserLinkProps extends LinkProps {
  trackingLabel?: string;
  mediaId?: string;
}

export const VideoTeaserLinkComponent: StandaloneComponent<VideoTeaserLinkProps> = ({
  href,
  mediaId,
  trackingLabel,
  options,
  ...props
}) => {
  return (
    <Link
      target="_blank"
      href={href}
      options={mergeOptions(
        {
          className: 'cts-impression-item',
        },
        options,
      )}
      data-video-id={mediaId}
      data-cts-label={trackingLabel}
      data-cts-name={trackingLabel}
      data-cts-id={mediaId}
      data-cts-creative={trackingLabel}
      {...props}
    />
  );
};
