import { useClassName } from '@/styles/JwVideoTeaser';
import { Component, ComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';

export interface VideoTeaserContentHintProps extends ComponentProps<'p'> {}

export const VideoTeaserContentHintComponent: Component<VideoTeaserContentHintProps> = ({ children, ...props }) => {
  const className = useClassName('content_hint', props, { defaults: { colors: 'primary' } });

  return <p {...mergeProps({ className }, props)}>{children}</p>;
};
