/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneJwVideoTeaser as ResolvedStandaloneJwVideoTeaser } from 'base/components/standalone/JwVideo/Teaser';

export const JwVideoTeaser = ResolvedStandaloneJwVideoTeaser;

export type JwVideoTeaserProps = PropsFromComponent<typeof JwVideoTeaser>;

export const JwVideoTeaserWith = (extras: DynamicStandaloneExtras): typeof JwVideoTeaser => {
    return function JwVideoTeaser(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'JwVideoTeaser');
        return <ResolvedStandaloneJwVideoTeaser {...mergeProps({ options: { theme } }, props)} />;
    }
}